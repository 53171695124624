import React, { useState, useCallback, useEffect } from "react";
import { useAuth0 } from "../../react-auth0-spa";
import { Alert, Form, Modal, Spin } from "antd";
import config from "../../api_config.json";
import { useAppContext } from "../../context";
import axios from "axios";
import AbbinaClienteForm from "./AbbinaClienteForm";
import { fetchClienteById } from "../../graphql/controllers/fetchClienti";

const mutationAbbinaCliente = `
mutation AbbinaCliente($id: ID!, $fields: UpdateClienteFieldsInput) {
  updateCliente(input: {id: $id, fields: $fields}) {
    cliente {
      objectId
      invite_status
    }
  }
}`

  .replace(/\r?\n|\r/g, "")
  .replace(/\t/g, " ");

const title = "Abbina Cliente";

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const AbbinaClienteModal = (props) => {
  const { getTokenSilently } = useAuth0();
  const { isProd } = useAppContext();
  const [loading, setLoading] = useState(false);
  const env = isProd ? "prod" : "dev";
  const [form] = Form.useForm();
  const [graphql, setGraphql] = useState({});
  const [failedAbbinaCliente, setFailedAbbinaCliente] = useState(false);

  useEffect(() => {
    const run = async () => {
      const token = await getTokenSilently();
      const graphqlUrl = `${config.baseUrl[env]}/graphql`;
      setGraphql({
        url: graphqlUrl,
        token,
      });
    };
    run();
  }, [env, getTokenSilently]);

  const abbinaCliente = useCallback(
    async (values) => {
      try {
        const variables = {
          id: props.clienteId,
          fields: {
            terzista_org_pointer: {
              link: values.orgId,
            },
          },
        };
        const cliente = await axios({
          method: "post",
          url: graphql.url,
          data: {
            query: mutationAbbinaCliente,
            variables,
          },
          headers: {
            Authorization: `Bearer ${graphql.token}`,
          },
        });

        return cliente.data.cliente;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    [props.clienteId, graphql]
  );

  const handleAbbinaCliente = useCallback(async () => {
    setLoading(true);
    const values = await form.validateFields();
    form.resetFields();
    let cliente = await abbinaCliente(values);
    let attempts = 0;
    const maxAttempts = 290; // lambda time limit is 900 seconds, so this limit is based on 3 seconds of sleep plus 0.1 seconds of query request
    do {
      attempts++;
      cliente = (await fetchClienteById(graphql, cliente.objectId)).data;
      await sleep(3 * 1000); // seconds * milliseconds for readability
    } while (cliente.invite_status !== "accepted" && attempts < maxAttempts);
    setLoading(false);
    if (cliente.invite_status !== "accepted") {
      setFailedAbbinaCliente(true);
    } else {
      props.onCreate();
    }
  }, [abbinaCliente, form, graphql, props]);

  return (
    <>
      <Modal
        visible={props.visible}
        title={title}
        okText="Salva"
        cancelText="Annulla"
        maskClosable={false}
        onCancel={() => {
          setLoading(false);
          props.onCancel();
        }}
        onOk={handleAbbinaCliente}
      >
        {!loading && !failedAbbinaCliente && (
          <AbbinaClienteForm form={form} clientiLinked={props.clientiLinked} />
        )}
        {loading && <Spin size="large" />}
        {failedAbbinaCliente && (
          <Alert
            message="Attenzione! Non è stato possibile terminare l'abbinamento tra il cliente e il fornitore. Contatta un dev."
            type="error"
          />
        )}
      </Modal>
    </>
  );
};

export default AbbinaClienteModal;
