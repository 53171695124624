import axios from "axios";
import React, { useState, useEffect } from "react";
import config from "../../api_config.json";
import { useAppContext } from "../../context";
import { useAuth0 } from "../../react-auth0-spa";
import { Select } from "antd";

const query = `
query Orgs {
  organizzaziones(first: 10000, order: createdAt_DESC) {
    edges{
      node{
        objectId
        nome
        plan
        tipo_istanza
        stripe_created_at
        giotto
        ofc
        data_scadenza
        data_scadenza_giotto
        percent_services_used
        email
      }
    }
  }
}`
  .replace(/\r?\n|\r/g, "")
  .replace(/\t/g, " ");

const SelectOrg = (props) => {
  const { getTokenSilently } = useAuth0();
  const { isProd } = useAppContext();
  const env = isProd ? "prod" : "dev";
  const [orgs, setOrgs] = useState([]);

  useEffect(() => {
    const fetchOrgs = async () => {
      if (props.orgs) {
        setOrgs(props.orgs);
        return;
      }
      const token = await getTokenSilently();

      const graphqlUrl = `${config.baseUrl[env]}/graphql`;
      const response = await axios({
        method: "post",
        url: graphqlUrl,
        data: {
          query,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (props.orgsToExclude) {
        setOrgs(
          response?.data.filter(
            (org) => !props.orgsToExclude.includes(org.objectId)
          )
        );
      } else {
        setOrgs(response?.data);
      }
    };
    fetchOrgs();
  }, [env, getTokenSilently, props.orgs, props.orgsToExclude]);

  return (
    <Select
      showSearch
      optionFilterProp="children"
      value={props.value}
      onChange={props.onChange}
      filterOption={(input, option) =>
        (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
      }
      options={orgs.map((org) => {
        return { value: org.objectId, label: `${org.nome} - ${org.objectId}` };
      })}
    />
  );
};

export default SelectOrg;
