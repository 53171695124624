import React, { useState, useEffect, useCallback } from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Badge, Breadcrumb, Card, Divider, Space, Tag, Typography } from "antd";
import { useAuth0 } from "../../react-auth0-spa";
import config from "../../api_config.json";
import { useAppContext } from "../../context";
import formatDistance from "date-fns/formatDistance";
import { it } from "date-fns/locale";
import InfiniteScroll from "react-infinite-scroll-component";
import ReactCompareImage from "react-compare-image";
import ParseLogo from "../../images/logos/parse.png";
import { Switch } from "antd";

const { Title } = Typography;

const mapToTitle = {
  removebg: "Giotto - Rimozione Sfondo",
  "image-enhancement": "Giotto - Migliora Foto",
  "magic-brush": "Giotto - Pennello Magico",
  "change-clothes": "Giotto - Cambia Vestiti",
  smile: "Giotto - Sorriso",
  "colorize-bw": "Giotto - Colorizza B/W",
};

const AttachmentCard = ({
  objectId,
  isProd,
  title,
  url,
  isComparative,
  beforeUrl,
  afterUrl,
  org,
  createdAt,
  extraLayerUrl,
  removebg_provider,
}) => {
  const [showExtraLayer, setShowExtraLayer] = useState(false);

  const handleChange = (value) => {
    setShowExtraLayer(value ? true : false);
  };

  return (
    <div className="grid-item">
      <div className="grid-item">
        <Card
          title={title}
          extra={
            <Space>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`${
                  config.parseDashboard[isProd ? "prod" : "dev"]
                }/browser/allegati?filters=[{"field":"objectId","constraint":"eq","compareTo":"${objectId}"}]`}
              >
                <img src={ParseLogo} width={20} alt="logo" />
              </a>{" "}
              |{" "}
              <a href={url} target="_blank" rel="noopener noreferrer">
                Org
              </a>
              {extraLayerUrl && (
                <>
                  | Mostra Maschera{" "}
                  <Switch onChange={handleChange} defaultChecked={false} />
                </>
              )}
            </Space>
          }
        >
          <div style={{ maxWidth: "100%" }}>
            {isComparative && (
              <ReactCompareImage
                leftImage={afterUrl}
                rightImage={beforeUrl}
                hover={true}
              />
            )}
            {showExtraLayer && (
              <img
                src={extraLayerUrl}
                alt="mask"
                style={{
                  maxWidth: "91%",
                  zIndex: 1,
                  position: "absolute",
                  top: "80px",
                  left: "26px",
                  opacity: 0.7,
                }}
              />
            )}
          </div>
          <Tag style={{ marginTop: "10px" }}>
            {formatDistance(new Date(createdAt), new Date(), { locale: it })}
          </Tag>
          {removebg_provider && (
            <Tag
              style={{ marginTop: "10px" }}
              color={removebg_provider === "pixianai" ? "cyan" : "purple"}
            >
              {removebg_provider || "removebg"}
            </Tag>
          )}
        </Card>
      </div>
    </div>
  );
};

const Attachments = (props) => {
  const [items, setItems] = useState([]);
  const [skipIndex, setSkipIndex] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const { isProd } = useAppContext();
  const { getTokenSilently } = useAuth0();

  const fetchData = useCallback(
    async (skip = 0) => {
      const limit = 12;

      try {
        const token = await getTokenSilently();

        const response = await fetch(
          `${
            config.baseUrl[isProd ? "prod" : "dev"]
          }/attachments?limit=${limit}&skip=${skip * limit}&type=${
            props.filter
          }`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const responseData = await response.json();
        return responseData;
      } catch (error) {
        console.error(error);
      }
    },
    [isProd, getTokenSilently, props.filter]
  );

  useEffect(() => {
    fetchData(0).then(({ data }) => {
      if (data.attachments.length === 0) {
        setHasMore(false);
      }
      setItems(data.attachments);
    });
  }, [fetchData, setItems]);

  return (
    <div style={{ margin: "16px 16px" }}>
      <Breadcrumb style={{ marginBottom: "20px" }}>
        <Breadcrumb.Item>Giotto</Breadcrumb.Item>
        <Breadcrumb.Item>{mapToTitle[props.filter]}</Breadcrumb.Item>
      </Breadcrumb>
      <Divider />
      <Title level={3} style={{ marginBottom: "40px" }}>
        <Space>
          {mapToTitle[props.filter]}
          <Badge overflowCount={5000} count={items.length}></Badge>
        </Space>
      </Title>
      <Divider />
      <InfiniteScroll
        dataLength={items.length}
        next={() => {
          setSkipIndex(skipIndex + 1);
          fetchData(skipIndex).then(({ data }) => {
            setItems(items.concat(data.attachments));
            if (data.attachments.length === 0) {
              setHasMore(false);
            }
          });
        }}
        hasMore={hasMore}
        loader={<LinearProgress />}
      >
        <div className="grid-container">
          {items.map((attachment, index) => {
            const url = `/clients/${attachment.org_pointer.objectId}`;

            const isComparative =
              attachment.isRemovebg ||
              attachment.isImageEnhancement ||
              attachment.isMagicEraser ||
              attachment.isChangeClothes ||
              attachment.isSmile ||
              attachment.isColorizeBw;

            let beforeUrl;

            if (attachment.enhancementRawImage) {
              beforeUrl = attachment.enhancementRawImage;
            }

            if (attachment.removebgRawImage) {
              beforeUrl = attachment.removebgRawImage;
            }

            if (attachment.magicEraserRawImage) {
              beforeUrl = attachment.magicEraserRawImage;
            }

            if (attachment.changeClothesRawImage) {
              beforeUrl = attachment.changeClothesRawImage;
            }

            if (attachment.smileRawImage) {
              beforeUrl = attachment.smileRawImage;
            }

            if (attachment.colorizeBwRawImage) {
              beforeUrl = attachment.colorizeBwRawImage;
            }

            let extraLayerUrl;

            if (attachment.isMagicEraser) {
              extraLayerUrl = attachment.magicEraserMask;
            }

            if (attachment.isChangeClothes) {
              extraLayerUrl = attachment.changeClothesMask;
            }

            return (
              <AttachmentCard
                key={index}
                objectId={attachment.objectId}
                createdAt={attachment.createdAt}
                isProd={isProd}
                org={attachment.org_pointer}
                title={""}
                url={url}
                isComparative={isComparative}
                beforeUrl={beforeUrl}
                afterUrl={attachment.allegato.url}
                extraLayerUrl={extraLayerUrl}
                removebg_provider={attachment.removebg_provider}
              />
            );
          })}
        </div>
      </InfiniteScroll>
    </div>
  );
};

export default Attachments;
